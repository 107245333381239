export const BODY_SCROLLER = 'body-scroller'
export const DRAWER_SCROLLER = 'drawer-scroller'

export const ANCHOR = {
  GLOBAL_BLUR_CLASS: 'GLOBAL_BLUR_CLASS',
  GLOBAL_HEADER_ID: 'GLOBAL_HEADER_ID',
  GLOBAL_TABBER_ID: 'GLOBAL_TABBER_ID',
  DRAWER_HEAD: 'drawer-head',

  COMMENTS_ID: 'COMMENTS_ID',
}
