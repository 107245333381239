import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M341.333 554.667v256a42.667 42.667 0 0 1-42.666 42.666h-85.334a42.667 42.667 0 0 1-42.666-42.666v-256A42.667 42.667 0 0 1 213.333 512h85.334a42.667 42.667 0 0 1 42.666 42.667zm213.334-384h-85.334a42.667 42.667 0 0 0-42.666 42.666v597.334a42.667 42.667 0 0 0 42.666 42.666h85.334a42.667 42.667 0 0 0 42.666-42.666V213.333a42.667 42.667 0 0 0-42.666-42.666zm256 170.666h-85.334A42.667 42.667 0 0 0 682.667 384v426.667a42.667 42.667 0 0 0 42.666 42.666h85.334a42.667 42.667 0 0 0 42.666-42.666V384a42.667 42.667 0 0 0-42.666-42.667z" />
    </svg>
  )
}

export default SVG
